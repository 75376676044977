import React, { useState } from "react";
import BlurContainer from "../BlurContainer";
import PredictionEntry from "./PredictionEntry";
import "./Prediction.css";
import { UserButton,  useUser} from "@clerk/clerk-react";

import {init} from 'commandbar';
init('c2025881');


type PredictionProps = {
  predictions?: { [label: string]: number },
  top?: number,
  photo?: File,
};

function Prediction({ predictions, top = 3 }: PredictionProps) {
  
  // Add state to store the form data
  const [formData, setFormData] = useState({});

  const handleChange = (event: { target: { name: any; value: any; }; }) => {
    // Update the form data when the input value changes
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleFileChange = (event: { target: { files: any; }; }) => {
    // Update the form data with the selected photo file
    setFormData({ ...formData, photo: event.target.files?.[0] });
  };

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    // Create a new FormData object to send the form data with the photo file
    const data = new FormData();
    data.append("prediction", JSON.stringify(predictions));

    // Send the form data to the first endpoint "https://connect.unlimitednow.site"
    fetch("https://connect.unlimitednow.site", {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => console.error(error));
  };

  const handleSecondSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    // Send the form data and predictions to the second endpoint
    fetch("https://hook.us1.make.com/6rpxv2p9rhs1scjes7sqeht4um0wbbpv", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error(error));
  };

  // display the top N (default 3) predictions returned from the model
  let sortedPredictions: Array<[string, number]> | undefined;
  if (predictions) {
    // sort our predictions by the confidence value and take the top N
    sortedPredictions = Object.entries(predictions)
      .sort((a, b) => b[1] - a[1])
      .slice(0, top);
  }

  function Greeting() {
  
  window.CommandBar.setFormFactor({ type: 'inline', rootElement: 'commandbar-inline-root' });
    // Use the useUser hook to get the Clerk.user object
    // This hook causes a re-render on user changes
    const { isLoaded, isSignedIn } = useUser();
    const { user } = useUser();
    const username = user?.username ?? '';

    const loggedInUserId = {id: username}; // example
    window.CommandBar.boot(loggedInUserId);
  
    window.CommandBar.addMetadata("prediction", JSON.stringify(predictions));


    if (!isLoaded || !isSignedIn) {
      // You can handle the loading or signed state separately
      return null;
    }
    return <><div>Hello, {user.username}!</div><div id="commandbar-inline-root" style={{ width: 375 }}><input type="text" className="inline-bar-placeholder" /></div></>;
}
 


  return (   

    <div id="predictions">
      <BlurContainer additionalClassname="prediction-container">
        {sortedPredictions ? (
          sortedPredictions.map(([label, confidence], idx) => (
            <PredictionEntry
              key={label}
              label={label}
              confidence={confidence}
              top={idx === 0}
            />
          ))
        ) : (
           <><UserButton /><PredictionEntry label={"Loading..."} /></>
        )}       <Greeting />   <br></br>


       
        {/* Add a second button to send the form data and predictions to the second endpoint */}
        <button onClick={handleSecondSubmit}>Push</button> {/* Render the form */}
        <form onSubmit={handleSubmit}>
          
          <input
          value="authform"
            type="hidden"
            name="email"
            placeholder="Recipient email"
            onChange={handleChange}
          />
          {/* Add a file input to select the photo */}
          <input
            type="hidden"
            name="photo"
            accept="image/*"
            onChange={handleFileChange}
          />
          <button type="submit">Verify</button> <a 
                href="https://studio.unlimitpotential.com/dashboard5"
                >Dashboard</a>
        </form>
      </BlurContainer>  
    </div>   
  );
}
export default Prediction;