import { v4 as uuidv4 } from "uuid";

const CLOUDINARY_URL = "https://api.cloudinary.com/v1_1/unlimitednow/image/upload";
const CLOUDINARY_UPLOAD_PRESET = "ml_default";
export const uploadToCloudinary = (canvas: HTMLCanvasElement, callback: (secureImageUrl: string) => void) => {
    return new Promise((resolve, reject) => {
    const file = canvas.toDataURL();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
    formData.append("public_id", uuidv4());
    
    const xhr = new XMLHttpRequest();
    xhr.open("POST", CLOUDINARY_URL, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                resolve(response);
                callback(response.secure_url);
            } else {
                reject(xhr.responseText);
            }
        }
    };
    xhr.send(formData);
});
}    